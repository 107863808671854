/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCountryCode } from "../../actions";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style file
import "react-date-range/dist/theme/default.css"; // Theme css file
import {
  browserName,
  isAndroid,
  isDesktop,
  isIOS,
  isMobile,
} from "react-device-detect";
import Ico from "../../Resources/img/ico.png";
import Xchng from "../../Resources/img/xchng.png";
import Ico1 from "../../Resources/img/ico1.png";
import Ico2 from "../../Resources/img/ico2.png";
//import Right from '../Resources/img/right.png';
import Favi1 from "../../Resources/img/favi1.png";
import Logo1 from "../../Resources/img/logo1.png";
import Favi2 from "../../Resources/img/favi2.ico";
import Logo2 from "../../Resources/img/logo3.png";
import Favi3 from "../../Resources/img/favi3.png";
import Logo3 from "../../Resources/assest/img/logo5.webp";
import Favi4 from "../../Resources/img/favi4.png";
import Logo4 from "../../Resources/assest/img/logo6.png";
import Logo5 from "../../Resources/assest/img/logo4.png";
import Logo6 from "../../Resources/assest/img/logo2.png";
import Fthy from "../../Resources/assest/img/fthy.png";
import Right from "../../Resources/assest/img/right.png";
import Banner from "../../Resources/assest/img/Call-banner.png";
import Banner2 from "../../Resources/assest/img/Call-banner-phone.png";
import Rt from "../../Resources/assest/img/rt.png";

export default function ModifySearch({ onClick, initialFormValues }) {
  const [APIData, setAPIData] = useState([]);
  const [filteredOriginResults, setFilteredOriginResults] = useState([]);
  const [filteredDestinationResults, setFilteredDestinationResults] = useState(
    []
  );
  const [originAirport, setOriginAirport] = useState("");
  const [destinationAirport, setDestinationAirport] = useState("");
  const [selectedOriginOption, setSelectedOriginOption] = useState(null);
  const [selectedDestinationOption, setSelectedDestinationOption] =
    useState(null);
  const [isVisible, setIsVisible] = useState(true);
  const [isVisibledes, setIsVisibledes] = useState(true);
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);
  const [infant, setInfant] = useState(0);
  const [infantWs, setInfantWs] = useState(0);
  const [error, setError] = useState("");
  const [travellerBlockVisible, setTravellerBlockVisible] = useState(false);
  const [visitorBlockVisible, setVisitorBlockVisible] = useState(false);
  const [searchOverlayVisible, setSearchOverlayVisible] = useState(false);
  const [activeField, setActiveField] = useState(false);
  const totalPassengers = parseInt(initialFormValues.adults) + parseInt(initialFormValues.child) + parseInt(initialFormValues.infants) + parseInt(initialFormValues.infantsWs);
  const [totalTraveller, setTotalTraveller] = useState(`${totalPassengers} Travellor(s)`);
  const [responseData, setResponseData] = useState(null);
  const [selectTripType, setSelectTripType] = useState(2);
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date();
  const tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
  const oneWeekFromToday = new Date(
    tomorrow.getTime() + 7 * 24 * 60 * 60 * 1000
  ); // Adding milliseconds for one week
  const oneYearFromToday = new Date();
  oneYearFromToday.setFullYear(today.getFullYear() + 1); // Add one year to the current date
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedOneDate, setSelectedOneDate] = useState(tomorrow);
  const [engineError, setEngineError] = useState(false);
  const [headingText, setHeadingText] = useState("");
  const [searchId, setSearchId] = useState("");
  const [direction, setDirection] = useState("horizontal");
  const [months, setMonths] = useState(2);
  const initialText = "";
  const typingInterval = 80;
  const [tripType, setTripType] = useState(true);
  const [originClick, setOriginClick] = useState(false);
  const [destinationclick, setDestinationClick] = useState(false);
  const [initialOrigin, setInitialOrigin] = useState(
    initialFormValues.segment[0].originAirport
  );
  const [initialDestination, setInitialDestination] = useState(
    initialFormValues.segment[0].destinationAirport
  );
  const [initialStart, setInitialStart] = useState(
    initialFormValues.segment[0].travelDate
  );
  const [initialEnd, setInitialEnd] = useState(
    initialFormValues?.segment[1]?.travelDate
  );
  const [searchClicked, setSearchClicked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);
  const [checked6, setChecked6] = useState(false);

  const [view1, setView1] = useState(false);
  const [view2, setView2] = useState(false);
  const [view3, setView3] = useState(false);
  const [view4, setView4] = useState(false);
  const [view5, setView5] = useState(false);
  const [view6, setView6] = useState(false);

  const [website, setWebsite] = useState("");
  const [website1, setWebsite1] = useState("");
  const [website2, setWebsite2] = useState("");
  const [website3, setWebsite3] = useState("");
  const [website4, setWebsite4] = useState("");
  const [website5, setWebsite5] = useState("");
  const [isDivVisible, setIsDivVisible] = useState(true); // Div is visible on desktop by default
  const [isMobileSet, setIsMobileSet] = useState(false);
  const [flightData, setFlightData] = useState(null);
  const [flightDataDes, setFlightDataDes] = useState(null);
  const [orgAirData, setOrgAirData] = useState("");
  const [desAirData, setDesAirData] = useState("");
  const [originInputValue, setOriginInputValue] = useState(orgAirData || "");
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://www.tgsapi.com/flights/CrossAirportList?data=${initialFormValues.segment[0].originAirport}&authcode=farehuts`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const filteredData = data.filter(
          (item) =>
            item.airportCode === initialFormValues.segment[0].originAirport
        );
        setFlightData(filteredData);
        if (filteredData.length > 0) {
          setOrgAirData(
            `${filteredData[0].airportCode} - ${filteredData[0].airportName}`
          );
        } else {
          setOrgAirData("No data found for origin airport");
        }
      } catch (error) {
        //console.error('Error fetching flight data:', error);
      }
    };

    fetchData();
  }, [initialFormValues.segment[0].originAirport]);
  ////console.log(flightData[0].airportCode)

  useEffect(() => {
    const fetchDataDes = async () => {
      try {
        const response = await fetch(
          `https://www.tgsapi.com/flights/CrossAirportList?data=${initialFormValues.segment[0].destinationAirport}&authcode=farehuts`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const filteredData = data.filter(
          (item) =>
            item.airportCode === initialFormValues.segment[0].destinationAirport
        );
        setFlightDataDes(filteredData);
        if (filteredData.length > 0) {
          setDesAirData(
            `${filteredData[0].airportCode} - ${filteredData[0].airportName}`
          );
        } else {
          setDesAirData("No data found for origin airport");
        }
      } catch (error) {
        //console.error('Error fetching flight data:', error);
      }
    };

    fetchDataDes();
  }, [initialFormValues.segment[0].destinationAirport]);

  const navigate = useNavigate();

  const [isLoding, setIsLoding] = useState(false);

  const calendarRef = useRef(null);

  const handleTripTypeChange = (event) => {
    setSelectTripType(Number(event.target.value));
  };

  useEffect(() => {
    let currentIndex = 0;
    let isQuestionMarkVisible = false;

    const typingEffect = () => {
      if (currentIndex < initialText.length) {
        setHeadingText(
          (prevText) => prevText + initialText.charAt(currentIndex++)
        );
        setTimeout(typingEffect, typingInterval);
      }
    };

    typingEffect(); // Start the typing effect when the component mounts
  }, []);

  const showTravellerBlock = () => {
    setTravellerBlockVisible(!travellerBlockVisible);
    //setSearchOverlayVisible(true);
    setActiveField(true);
  };

  const hideTravellerBlock = () => {
    setTravellerBlockVisible(!travellerBlockVisible);
    setSearchOverlayVisible(false);
    setActiveField(false);
    calculateTraveller();
  };

  const handleMinusClick = (pax, event) => {
    event.stopPropagation();
    if (pax === "adult") {
      setAdult((prevAdult) => Math.max(prevAdult - 1, 1));
    } else if (pax === "child") {
      setChild((prevChild) => Math.max(prevChild - 1, 0));
    } else if (pax === "infant" && infant <= adult) {
      setInfant((prevInfant) => Math.max(prevInfant - 1, 0));
    } else if (pax === "infantWs") {
      setInfantWs((prevInfantWs) => Math.max(prevInfantWs - 1, 0));
    }
  };

  const handlePlusClick = (pax, event) => {
    event.stopPropagation();
    const totalCount = adult + child + infant + infantWs;

    if (totalCount < 9) {
      if (pax === "adult") {
        setAdult((prevAdult) => prevAdult + 1);
      } else if (pax === "child") {
        setChild((prevChild) => prevChild + 1);
      } else if (pax === "infant") {
        if (infant < adult) {
          setInfant((prevInfant) => prevInfant + 1);
        } else {
          // Handle the error condition if infant count exceeds adult count
        }
      } else if (pax === "infantWs") {
        if (infantWs < adult) {
          setInfantWs((prevInfantWs) => prevInfantWs + 1);
        } else {
          // Handle the error condition if infantWs count exceeds adult count
        }
      }
    } else {
      // Handle the error condition if the total traveler count exceeds 9
    }
  };

  const [formValues, setFormValues] = useState({
    searchId: searchId,
    client: "1",
    segment: initialFormValues?.segment || [
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: formatDate(selectedStartDate),
      },
      {
        originAirport: "",
        destinationAirport: "",
        travelDate: formatDate(selectedEndDate),
      },
    ],
    tripType: initialFormValues.tripType || "",
    adults: initialFormValues.adults || adult,
    child: initialFormValues.child || child,
    infants: initialFormValues.infants || infant,
    infantsWs: initialFormValues.infantsWs || infantWs,
    cabinType: initialFormValues.cabinType || "1",
    currencyCode: "USD",
    siteId: "16",
    source: "Online",
    media: "Online",
    locale: "en",
    page: "Online",
    pageValue: "Online",
    userIP: "",
    serverIP: "",
    device: "mobile",
    browser: browserName,
  });

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      adults: adult,
      child: child,
      infants: infant,
      infantsWs: infantWs,
    }));
  }, [adult, child, infant, infantWs]);

  const calculateTraveller = () => {
    const totalCount = adult + child + infant + infantWs;
    setTotalTraveller(totalCount || "Travellor(s)");
  };

  useEffect(() => {
    calculateTraveller();
  }, [adult, child, infant, infantWs]);

  useEffect(() => {
    const fetchData = async () => {
      const cachedData = localStorage.getItem("cachedData");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setAPIData(parsedData);
      } else {
        try {
          // const response = await axios.get(`http://54.70.41.205/flights/CrossAirportList?data=${originAirport}&authcode=farehuts`);
          const response = await axios.get(
            `https://www.tgsapi.com/flights/CrossAirportList?data=${originAirport}&authcode=farehuts`
          );

          const fetchedData = response.data;
          localStorage.setItem("cachedData", JSON.stringify(fetchedData));
          setAPIData(fetchedData);
          // ////console.log(fetchedData)
        } catch (error) {
          // Handle the error, e.g., display an error message
          // ////console.error(error);
        }
      }
    };
    fetchData();
  }, [originAirport]);

  const searchItems = (event) => {
    const searchValue = event.target.value;
    setOriginAirport(searchValue);

    if (searchValue.length > 2) {
      const matchingAirportCode = APIData.find(
        (item) => item.airportCode.toLowerCase() === searchValue.toLowerCase()
      );

      const filteredByName = APIData.filter(
        (item) =>
          item.airportCode.toLowerCase() !== searchValue.toLowerCase() &&
          (item.airportName.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.cityName.toLowerCase().includes(searchValue.toLowerCase()))
      ).slice(0, 9);

      const filteredResults = matchingAirportCode
        ? [matchingAirportCode, ...filteredByName]
        : filteredByName;

      setFilteredOriginResults(filteredResults);
    } else {
      setFilteredOriginResults([]);
    }
  };
  // console.log(filteredOriginResults)

  useEffect(() => {
    //if(formValues.segment[0].originAirport.length > 2){
    const fetchData = async () => {
      const cachedData = localStorage.getItem("cachedData");
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        setAPIData(parsedData);
      } else {
        try {
          // const response = await axios.get(`http://54.70.41.205/flights/CrossAirportList?data=${destinationAirport}&authcode=farehuts`);
          const response = await axios.get(
            `https://www.tgsapi.com/flights/CrossAirportList?data=${destinationAirport}&authcode=farehuts`
          );

          const fetchedData = response.data;
          localStorage.setItem("cachedData", JSON.stringify(fetchedData));
          setAPIData(fetchedData);
        } catch (error) {
          // Handle the error, e.g., display an error message
          //////console.error(error);
        }
      }
    };
    fetchData();
    //}
  }, [destinationAirport]);

  const searchItemsDestination = (event) => {
    event.persist();
    const searchValueDestination = event.target.value;
    setDestinationAirport(searchValueDestination);

    if (searchValueDestination.length > 2) {
      const matchingAirportCode = APIData.find(
        (item) =>
          item.airportCode.toLowerCase() ===
          searchValueDestination.toLowerCase()
      );

      const filteredByName = APIData.filter(
        (item) =>
          item.airportCode.toLowerCase() !==
            searchValueDestination.toLowerCase() &&
          (item.airportName
            .toLowerCase()
            .includes(searchValueDestination.toLowerCase()) ||
            item.cityName
              .toLowerCase()
              .includes(searchValueDestination.toLowerCase()))
      ).slice(0, 9);

      const filteredResults = matchingAirportCode
        ? [matchingAirportCode, ...filteredByName]
        : filteredByName;

      setFilteredDestinationResults(filteredResults);
      //////console.log('here we have', filteredResults);
    } else {
      setFilteredDestinationResults([]);
    }
  };

  const handleOnSelectOrigin = (item) => {
    setSelectedOriginOption(item);
    // //console.log(selectedOriginOption.airportName)
    setOriginAirport(item.airportCode);
    setFormValues((prevState) => ({
      ...prevState,
      segment: [
        {
          ...prevState.segment[0],
          originAirport: item.airportCode.toUpperCase(),
        },
        ...prevState.segment.slice(1),
      ],
    }));
    // Handle selection logic here
  };

  //console.log(selectedOriginOption.countryCode)
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedOriginOption && selectedOriginOption.countryCode) {
      dispatch(setCountryCode(selectedOriginOption.countryCode));
    }
  }, [dispatch, selectedOriginOption]);

  const handleOnSelectDestination = (item) => {
    setSelectedDestinationOption(item);
    setDestinationAirport(item.airportCode);
    setFormValues((prevState) => ({
      ...prevState,
      segment: [
        {
          ...prevState.segment[0],
          destinationAirport: item.airportCode.toUpperCase(),
        },
        ...prevState.segment.slice(1),
      ],
    }));
    // Handle selection logic here
  };

  const handleClickDestination = () => {
    setIsVisibledes(!isVisibledes);
  };

  const [calendarVisible, setCalendarVisible] = useState(false);
  useEffect(() => {
    if (calendarVisible && calendarRef.current) {
      calendarRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // console.log()
  }, [calendarVisible]);

  const handleInputFocus = () => {
    setCalendarVisible(true);
  };

  const handleSelect = (ranges) => {
    setSelectedStartDate(ranges.selection.startDate);
    setSelectedEndDate(ranges.selection.endDate);
    setSelectedOneDate(ranges.selection.startDate);
  };

  const handleOneWaySelect = (range) => {
    setSelectedOneDate(range.selection.startDate);
    setFormValues((prevState) => ({
      ...prevState,
      segment: [
        {
          ...prevState.segment[0],
          travelDate: formatDate(range.selection.startDate), // Format date here
        },
      ],
    }));
  };

  const handleApply = () => {
    //console.log(selectedOneDate.toLocaleDateString());
    //////console.log("Selected Range:", selectedStartDate, selectedEndDate);
    // You can perform any actions here with the selected range
    setCalendarVisible(false);
  };

  const isDayDisabled = (date) => {
    return date < today;
  };

  const HandleSwapping = () => {
    setDestinationAirport(originAirport);
    setSelectedDestinationOption(selectedOriginOption);
    setOriginAirport(destinationAirport);
    setSelectedOriginOption(selectedDestinationOption);
  };

  const getCabinTypeLabel = (cabinTypeValue) => {
    switch (cabinTypeValue) {
      case "1":
        return "Economy";
      case "2":
        return "Premium Economy";
      case "3":
        return "Business";
      case "4":
        return "First";
      default:
        return "Unknown";
    }
  };

  const [isUrlsSet, setIsUrlsSet] = useState(false);
  // Add useEffect to watch the URLs and open them once they are set
  useEffect(() => {
    if (isUrlsSet) {
      if (checked1) openNewTab(website);
      if (checked2) setTimeout(() => openNewTab(website1), 1000);
      if (checked3) setTimeout(() => openNewTab(website2), 2000);
      if (checked4) setTimeout(() => openNewTab(website3), 3000);
      if (checked5) setTimeout(() => openNewTab(website4), 4000);
      if (checked6) setTimeout(() => openNewTab(website5), 5000);
    }
  }, [isUrlsSet]);

  const openNewTab = (url) => {
    try {
      window.open(url, "_blank", "noopener,noreferrer");
      //console.log('Opened tab:', url);
    } catch (error) {
      //console.error('Error opening tab:', error);
    }
  };
  function formatDate(date) {
    const formattedDate = new Date(date);
    const month = formattedDate.getMonth() + 1;
    const day = formattedDate.getDate();
    const year = formattedDate.getFullYear();
    return `${month.toString().padStart(2, "0")}/${day
      .toString()
      .padStart(2, "0")}/${year}`;
  }

  const [urls, setUrls] = useState([]);
  const [checkedUrls, setCheckedUrls] = useState({});
  const [prevOrg, setPrevOrg] = useState();
  const [prevDes, setPrevDes] = useState();
  const [prevForm, setPrevForm] = useState(initialFormValues);

  const handleViewSec = async () => {
    if (prevForm === formValues) {
      alert("Please Do not hit search with the previous search criteria!");
    } else {
      const deviceName = isMobile
        ? "Mobile Device"
        : isDesktop
        ? "Desktop"
        : isAndroid
        ? "Android"
        : isIOS;
      // const response = await axios.get("https://api.ipify.org?format=json");
      // const userIP = response.data.ip;
      const newSearchID = uuidv4().slice(0, 16);
      setSearchId(newSearchID);

      let updatedSegment;
      if (selectTripType === 2) {
        const [month1, day1, year1] =
          formValues.segment[0].travelDate.split("/");
        const formattedDate1 = `${year1}-${month1.padStart(
          2,
          "0"
        )}-${day1.padStart(2, "0")}`;
        // console.log(formattedDate1); // Output: 2024-11-28
        const [month, day, year] =
          formValues?.segment[1]?.travelDate.split("/");
        const formattedDate2 = `${year}-${month.padStart(
          2,
          "0"
        )}-${day.padStart(2, "0")}`;
        // console.log(formattedDate1); // Output: 2024-11-28
        updatedSegment = [
          {
            originAirport: formValues.segment[0].originAirport,
            destinationAirport: formValues.segment[0].destinationAirport,
            travelDate: formattedDate1,
          },
          {
            originAirport: formValues.segment[0].destinationAirport,
            destinationAirport: formValues.segment[0].originAirport,
            travelDate: formattedDate2 || "",
          },
        ];
      } else if (selectTripType === 1) {
        const [month1, day1, year1] =
          formValues.segment[0].travelDate.split("/");
        const formattedDate1 = `${year1}-${month1.padStart(
          2,
          "0"
        )}-${day1.padStart(2, "0")}`;
        // console.log(formattedDate1); // Output: 2024-11-28
        updatedSegment = [
          {
            originAirport: formValues.segment[0].originAirport,
            destinationAirport: formValues.segment[0].destinationAirport,
            travelDate: formattedDate1,
          },
        ];
      }

      const tripTypeAsString = selectTripType.toString();
      const updatedFormValues = {
        ...formValues,
        tripType: tripTypeAsString,
        segment: updatedSegment,
        searchId: newSearchID,
        // userIP: userIP,
        device: deviceName,
        // cabinType: getFinalCabinTypeLabel(formValues.cabinType)
      };
      // console.log("updatedFormValuesupdatedFormValues", updatedFormValues)

      try {
        setIsLoding(true);
        // console.log("modifySearchClick", updatedFormValues);
        navigate("/listing", { state: { updatedFormValues } });
      } catch (e) {
        // console.log(e);
      } finally {
        setIsLoding(false);
      }
    }
  };

  const coreMetaClick = async () => {
    const deviceName = isMobile
      ? "Mobile Device"
      : isDesktop
      ? "Desktop"
      : isAndroid
      ? "Android"
      : isIOS;
    // const response = await axios.get("https://api.ipify.org?format=json");
    // const userIP = response.data.ip;
    const newSearchID = uuidv4().slice(0, 16);
    setSearchId(newSearchID);

    let updatedSegment;
    if (selectTripType === 2) {
      updatedSegment = [
        {
          originAirport: formValues.segment[0].originAirport,
          destinationAirport: formValues.segment[0].destinationAirport,
          travelDate: formValues.segment[0].travelDate,
        },
        {
          originAirport: formValues.segment[0].destinationAirport,
          destinationAirport: formValues.segment[0].originAirport,
          travelDate: formValues.segment[1].travelDate || "",
        },
      ];
    } else if (selectTripType === 1) {
      updatedSegment = [
        {
          originAirport: formValues.segment[0].originAirport,
          destinationAirport: formValues.segment[0].destinationAirport,
          travelDate: formValues.segment[0].travelDate,
        },
      ];
    }

    const tripTypeAsString = selectTripType.toString();
    const updatedFormValues = {
      ...formValues,
      tripType: tripTypeAsString,
      segment: updatedSegment,
      searchId: newSearchID,
      // userIP: userIP,
      device: deviceName,
    };

    try {
      setIsLoding(true);
      // console.log("hbnuihjk", updatedFormValues);
      navigate("/listing", { state: { updatedFormValues } });

      // Sending POST request using fetch
      // const response = await fetch("http://localhost:5001/api/website/get-deep-link", {
      //     method: 'POST',
      //     headers: {
      //         'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify(updatedFormValues),
      // });

      // const reader = response.body.getReader();
      // const decoder = new TextDecoder("utf-8");

      // // Read data chunks
      // while (true) {
      //     const { done, value } = await reader.read();
      //     if (done) break; // Exit if no more data

      //     const chunk = decoder.decode(value, { stream: true });
      //     console.log("Received chunk:", chunk);

      //     // Assuming each chunk is a valid JSON string
      //     try {
      //         const data = JSON.parse(chunk);
      //         // Process the data as required (e.g., update state)
      //     } catch (error) {
      //         console.error("Error parsing chunk:", error);
      //     }
      // }
    } catch (e) {
      // console.log(e);
    } finally {
      setIsLoding(false);
    }
  };

  // useEffect(() => {
  //   handleView();
  // }, []);

  const handleCheckboxChange = (url, checked) => {
    setCheckedUrls((prevState) => ({
      ...prevState,
      [url]: checked,
    }));
  };

  const handleOpenCheckedUrls = () => {
    const urlsToOpen = Object.keys(checkedUrls).filter(
      (url) => checkedUrls[url]
    );
    urlsToOpen.forEach((url, index) => {
      setTimeout(() => {
        document.getElementById(`urlButton-${index}`).click();
      }, index * 100); // Opens each URL with a 100ms delay
    });
  };

  const handleClick = (url) => {
    window.open(url, "_blank");
  };

  //const urls = [];

  const [calendarValue, setCalendarValue] = useState([
    formValues.segment[0]?.travelDate
      ? new Date(formValues.segment[0].travelDate)
      : null,
    formValues.segment[1]?.travelDate
      ? new Date(formValues.segment[1].travelDate)
      : null,
  ]);
  useEffect(() => {
    //////console.log("Updated Segment:", formValues.segment);
  }, [formValues.segment]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        // On smaller screens, display calendars vertically
        setDirection("vertical");
        setMonths(1);
      } else {
        // On larger screens, display calendars horizontally
        setDirection("horizontal");
        setMonths(2);
      }
    }

    // Initial setup
    handleResize();

    // Add event listener to update the layout when the screen size changes
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const [direction, setDirection] = useState('horizontal');
  // const [months, setMonths] = useState(2);

  useEffect(() => {
    if (selectedOriginOption) {
      // When selectedOriginOption changes, focus on the destination input element
      //destinationInputRef.current.focus();
      //console.log(selectedOriginOption)
    }
  }, [selectedOriginOption]);

  useEffect(() => {
    const defaultOpenElement = document.getElementById("defaultOpen");
    if (defaultOpenElement) {
      defaultOpenElement.click();
    }
  }, []);

  const logoMap = {
    0: Logo1,
    1: Logo2,
    2: Logo3,
    3: Logo4,
    4: Logo5,
    5: Logo6,
  };

  // Check if the device is mobile on initial render and on resize
  useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobileSet(mobile);

      if (mobile) {
        setIsDivVisible(false); // Hide div on mobile
      } else {
        setIsDivVisible(true); // Show div on desktop
      }
    };
    // console.log("Clicked")
    checkMobile(); // Initial check
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  // Button click handler to toggle div visibility
  const toggleDivVisibility = () => {
    setIsDivVisible(!isDivVisible);
  };

  //  useEffect(()=>{
  //   console.log("initialFormValues.segment[0].originAirport", initialFormValues.segment[0].originAirport)
  //   setSelectedOriginOption(initialFormValues.segment[0].originAirport)
  //   console.log("selected", selectedOriginOption)
  //  }, [])

  // console.log("onclick", onClick)
  return (
    <section className="main-body-listing" id="uftg">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          {isDivVisible ? (
            <div className="col-lg-12 px-5 main-bg degt56t dtdtd">
              {onClick ? (
                <>
                  <div className="tab-brder">
                    <input
                      className="clr-chng"
                      type="radio"
                      id="tab1"
                      name="tab"
                      value="2"
                      onClick={(e) => {
                        setTripType(true);
                        handleTripTypeChange(e);
                      }}
                      checked={tripType}
                    />
                    <label className="ms-2" htmlFor="tab1">
                      Round Trip
                    </label>
                    <input
                      className="ms-2 clr-chng"
                      type="radio"
                      id="tab2"
                      name="tab"
                      value="1"
                      onClick={(e) => {
                        setTripType(false);
                        handleTripTypeChange(e);
                      }}
                      checked={!tripType}
                    />
                    <label className="ms-2" htmlFor="tab2">
                      One Way
                    </label>
                  </div>
                </>
              ) : null}

              <div className="form mt-3 ">
                <div className="row g-3 rela ">
                  <div className="col-lg-5">
                    <div className="row">
                      <div className="col-md-12 col-lg-6 rela pe-lg-0 skmujk">
                        <div className="form-floating trhth ">
                          <input
                            type="text"
                            className="form-control ertsg"
                            id="floatingInput"
                            value={
                              selectedOriginOption
                                ? `${selectedOriginOption.airportName}${
                                    selectedOriginOption.countryName
                                      ? " , " + selectedOriginOption.countryName
                                      : ""
                                  }`
                                : orgAirData
                            }
                            onChange={(event) => {
                              const inputValue =
                                event.target.value.toUpperCase();

                              searchItems(event);

                              setSelectedOriginOption(null); // This should be done conditionally
                              setInitialOrigin(null);
                              setOrgAirData(inputValue);

                              setFormValues((prevState) => ({
                                ...prevState,
                                segment: [
                                  {
                                    ...prevState.segment[0],
                                    originAirport: inputValue,
                                  },
                                  ...prevState.segment.slice(1),
                                ],
                              }));
                            }}
                            onFocus={() => {
                              setIsVisible(true);
                              setOriginClick(true);
                              // Hide the <p> element when the input field is focused
                              //document.querySelector('.OriginAirport').style.display = 'none';
                            }}
                            onBlur={() => {
                              //setOriginClick(false);
                              // Show the <p> element when the input field loses focus
                              // document.querySelector('.asdfgsd').style.display = 'block';
                            }}
                            style={{ textTransform: "uppercase" }}
                            required
                            placeholder="From"
                            autoComplete="off"
                            autoCorrect="off"
                            spellCheck="false"
                          />
                          <label htmlFor="floatingInput ">From</label>
                        </div>
                        <img
                          className="form-ico erfe safasf"
                          src={Ico}
                          alt=""
                        />
                        {/* <!-- <ul className="asdfgsd srtsd">
                                    <li><img className="dger" src="assest/img/ico.png" alt="">(SFO), San Francisco-San Francisco , US</li>
                                    <li><img className="dger " src="assest/img/ico.png" alt="">(SFO), San Francisco-San Francisco , US</li>
                                  </ul> --> */}
                        {originClick ? (
                          <ul
                            className={`asdfgsd ${
                              filteredOriginResults.length > 0 ? "tutht" : ""
                            }`} /*onClick={handleClickOrigin}*/
                          >
                            {originAirport.length > 2
                              ? filteredOriginResults.map((item) => {
                                  return (
                                    <>
                                      {isVisible && (
                                        <li
                                          key={item.airportCode}
                                          onClick={() => {
                                            handleOnSelectOrigin(item);
                                            setOriginClick(false);
                                            // handleClickOrigin()
                                          }}
                                        >
                                          <div
                                            onClick={() => {
                                              handleOnSelectOrigin(item);
                                            }}
                                          >
                                            <img
                                              className="dger"
                                              src={Ico}
                                              alt="" /*onClick={handleClickOrigin}*/
                                            />
                                            ({item.airportCode}),
                                            {item.airportName}-{item.cityName} ,{" "}
                                            {item.countryCode}
                                          </div>
                                        </li>
                                      )}
                                    </>
                                  );
                                })
                              : null}
                            {/* <li><img className="dger" src="assest/img/ico.png" alt="" />(SFO), San Francisco-San Francisco , US</li> */}
                          </ul>
                        ) : null}
                      </div>
                      <img
                        className="icon-xchng1 rt7tur"
                        src={Xchng}
                        alt=""
                        onClick={HandleSwapping}
                      />
                      <div className="col-md-12 col-lg-6 rela pe-lg-0 ps-lg-1">
                        <div className="form-floating trhth usnrr">
                          <input
                            type="text"
                            className="form-control ertsg indyt"
                            id="floatingInput"
                            value={
                              selectedDestinationOption
                                ? `${selectedDestinationOption.airportName}${
                                    selectedDestinationOption.countryName
                                      ? " , " +
                                        selectedDestinationOption.countryName
                                      : ""
                                  }` /*selectedOriginOption.airportCode.toUpperCase()*/
                                : desAirData
                            }
                            onChange={(event) => {
                              searchItemsDestination(event);
                              setSelectedDestinationOption(null);
                              setInitialDestination(null);
                              setDesAirData(event.target.value.toUpperCase());
                            }}
                            onFocus={() => {
                              setIsVisibledes(true);
                              setDestinationClick(true);
                            }}
                            required
                            placeholder="To"
                            autoComplete="off"
                            autoCorrect="off"
                            spellCheck="false"
                          />
                          <label htmlFor="floatingInput ">To</label>
                        </div>
                        <img
                          className="form-ico erfe safasf"
                          src={Ico}
                          alt=""
                        />
                        {destinationclick ? (
                          <ul
                            className={`asdfgsd ${
                              filteredDestinationResults.length > 0
                                ? "tutht"
                                : ""
                            }`}
                            onClick={handleClickDestination}
                          >
                            {destinationAirport.length > 2
                              ? filteredDestinationResults.map((item) => {
                                  return (
                                    <>
                                      {isVisibledes && (
                                        <li
                                          key={item.airportCode}
                                          onClick={() => {
                                            handleOnSelectDestination(item);
                                            setDestinationClick(false);
                                          }}
                                        >
                                          <img
                                            className="dger"
                                            src={Ico}
                                            alt=""
                                          />
                                          ({item.airportCode}),{" "}
                                          {item.airportName}-{item.cityName} ,{" "}
                                          {item.countryCode}{" "}
                                        </li>
                                      )}
                                    </>
                                  );
                                })
                              : null}
                          </ul>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {tripType ? (
                    <div className="col-md-12 col-lg-3  rela pe-lg-0 d-flex">
                      <div className="form-floating trhth date-in date rydse">
                        <input
                          className="form-control ertsg fgyf"
                          type="text"
                          id="date-in"
                          value={
                            selectedStartDate
                              ? selectedStartDate.toLocaleDateString()
                              : initialStart
                          }
                          onFocus={handleInputFocus}
                        />
                        {/* <input  type="hidden" id="floatingInputValue" name="datein" value="04/24/2015" /> */}
                        <label htmlFor="floatingInputValue ">Depart</label>
                        <img className="form-ico1 erfe1" src={Ico1} alt="" />
                        <div
                          className="two-month-range-calendar-container"
                          style={{ zIndex: "99" }}
                        >
                          {/* <h2>Two-Month Range Calendar</h2> */}
                          {calendarVisible && (
                            <div>
                              <div style={{ backgroundColor: "white" }}>
                                <DateRangePicker
                                  onChange={(selectedDates) => {
                                    handleSelect(selectedDates);

                                    // Update selectedStartDate and selectedEndDate directly
                                    setSelectedStartDate(
                                      selectedDates.selection.startDate
                                    );
                                    setSelectedEndDate(
                                      selectedDates.selection.endDate
                                    );

                                    setFormValues((prevState) => ({
                                      ...prevState,
                                      segment: [
                                        {
                                          ...prevState.segment[0],
                                          travelDate: formatDate(
                                            selectedDates.selection.startDate
                                          ),
                                        },
                                        {
                                          ...prevState.segment[1],
                                          travelDate: formatDate(
                                            selectedDates.selection.endDate
                                          ),
                                        },
                                      ],
                                    }));

                                    if (tripType === true) {
                                      // console.log("Trip Type:", tripType);
                                      // console.log("Selected Start Date:", selectedStartDate);
                                      // console.log("Selected End Date:", selectedEndDate);
                                      if (selectedEndDate && selectedEndDate.getTime() === selectedStartDate.getTime()) {
                                        setCalendarVisible(false);
                                      }
                                    } else {
                                      // console.log("Trip Type:", tripType);
                                      // For other trip types, close the calendar after selecting the start date
                                      setCalendarVisible(false);
                                    }

                                    setCalendarValue(selectedDates);
                                  }}
                                  ranges={[
                                    {
                                      startDate: selectedStartDate,
                                      endDate: selectedEndDate,
                                      key: "selection",
                                    },
                                  ]}
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  months={months}
                                  showMonthAndYearPickers={true} // Show month and year picker buttons
                                  showDateDisplay={true} // Show the date display on the top
                                  direction={direction}
                                  minDate={today}
                                  maxDate={oneYearFromToday}
                                  isDayBlocked={isDayDisabled} // Disable past dates
                                  style={{
                                    width: "300px",
                                    height: "250px",
                                    borderColor: "gray",
                                    borderRadius: "3px",
                                    zIndex: "5",
                                  }}
                                />
                                <div
                                  className="button-container"
                                  style={{
                                    backgroundColor: "white",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    alignItems: "center",
                                    margin: "5px",
                                  }}
                                >
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-floating trhth date-out date rydse">
                        <input
                          className="form-control ertsg fgyf1"
                          type="text"
                          id="date-out"
                          value={
                            selectedEndDate
                              ? selectedEndDate.toLocaleDateString()
                              : initialEnd
                          }
                          onFocus={handleInputFocus}
                        />
                        {/* <input  type="hidden" id="floatingInputValue" name="datein" value="04/24/2015" /> */}
                        <label htmlFor="floatingInputValue ">Return</label>
                        <img className="form-ico1 erfe1 " src={Ico1} alt="" />
                      </div>
                    </div>
                  ) : (
                    <div className="col-md-12 col-lg-3  rela pe-lg-0 d-flex">
                      <div className="form-floating trhth date-in date rydse">
                        <input
                          className="form-control ertsg fgyf"
                          type="text"
                          id="date-in"
                          value={selectedOneDate.toLocaleDateString()}
                          onFocus={handleInputFocus}
                        />
                        {/* <input  type="hidden" id="floatingInputValue" name="datein" value="04/24/2015" /> */}
                        <label htmlFor="floatingInputValue ">Depart</label>
                        <img className="form-ico1 erfe1" src={Ico1} alt="" />
                        <div
                          className="two-month-range-calendar-container"
                          style={{ zIndex: "5" }}
                        >
                          {/* <h2>Two-Month Range Calendar</h2> */}
                          {calendarVisible && (
                            <div style={{ backgroundColor: "white" }}>
                              <div
                                className="range-calendar-container"
                                style={{ position: "absolute" }}
                              >
                                {/* <h2>Range Calendar</h2> */}
                                <DateRangePicker
                                  onChange={(e) => {
                                    handleOneWaySelect(e);
                                    setFormValues((prevState) => ({
                                      ...prevState,
                                      segment: [
                                        {
                                          ...prevState.segment[0],
                                          travelDate: formatDate(
                                            selectedOneDate.toLocaleDateString()
                                          ),
                                        },
                                      ],
                                    }));
                                    setCalendarVisible(false);
                                  }}
                                  ranges={[
                                    {
                                      startDate: selectedOneDate,
                                      endDate: selectedOneDate,
                                      key: "selection",
                                    },
                                  ]}
                                  months={months}
                                  showSelectionPreview={true}
                                  moveRangeOnFirstSelection={false}
                                  direction={direction}
                                  minDate={today}
                                  maxDate={oneYearFromToday}
                                  isDayBlocked={isDayDisabled} // Disable past dates
                                  style={{
                                    width: "300px",
                                    height: "250px",
                                  }}
                                />
                                <div
                                  className="button-container"
                                  style={{
                                    backgroundColor: "white",
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    alignItems: "center",
                                    margin: "0px",
                                  }}
                                >
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-md-12 col-lg-2 rela pe-lg-0">
                    <img className="form-ico erfe" src={Ico2} alt="" />
                    <div className="form-floating trhth trav_engine">
                      <input
                        type="text"
                        id="floatingInputValue"
                        className="form-control ertsg txt_Traveler"
                        value={
                          totalTraveller === 0
                            ? "Traveler(s)"
                            : `${totalTraveller} Traveler(s), ${getCabinTypeLabel(
                                formValues.cabinType
                              )}`
                        }
                        onClick={showTravellerBlock}
                        readonly="readonly"
                      />
                      <label
                        htmlFor="floatingInputValue "
                        onClick={showTravellerBlock}
                      >
                        Travelers & Class
                      </label>
                      {/* <div className="trav_toggle open">
                                    <a href="#">Open</a>
                                </div> */}
                      {travellerBlockVisible && (
                        <div
                          className="trav_form" /*style={{display: 'none'}}*/
                        >
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-7 border-end mt-2">
                                <div className="trav_item">
                                  <div className="trav_inner1">
                                    Adults
                                    <span>12+ yrs</span>
                                  </div>
                                  <div className="trav_inner2">
                                    <a
                                      href="javascript:void(0)"
                                      className="minus"
                                      onClick={(e) =>
                                        handleMinusClick("adult", e)
                                      }
                                    >
                                      -
                                    </a>
                                    <input
                                      type="text"
                                      value={adult ? adult : formValues.adults}
                                      id="ddlAdult"
                                      className="txt_trav"
                                      readonly="readonly"
                                      onkeypress="return false;"
                                    />
                                    <a
                                      href="javascript:void(0)"
                                      className="plus"
                                      onClick={(e) =>
                                        handlePlusClick("adult", e)
                                      }
                                    >
                                      +
                                    </a>
                                  </div>
                                </div>
                                <div className="trav_item">
                                  <div className="trav_inner1">
                                    Children
                                    <span>2 - 11 yrs</span>
                                  </div>
                                  <div className="trav_inner2">
                                    <a
                                      href="javascript:void(0)"
                                      className="minus"
                                      onClick={(e) =>
                                        handleMinusClick("child", e)
                                      }
                                    >
                                      -
                                    </a>
                                    <input
                                      type="text"
                                      value={child ? child : formValues.child}
                                      id="ddlChild"
                                      className="txt_trav"
                                      readonly="readonly"
                                      onkeypress="return false;"
                                    />
                                    <a
                                      href="javascript:void(0)"
                                      className="plus"
                                      onClick={(e) =>
                                        handlePlusClick("child", e)
                                      }
                                    >
                                      +
                                    </a>
                                  </div>
                                </div>
                                <div className="trav_item">
                                  <div className="trav_inner1">
                                    Infants (Lap)
                                    <span>under 2 yrs</span>
                                  </div>
                                  <div className="trav_inner2">
                                    <a
                                      href="javascript:void(0)"
                                      className="minus"
                                      onClick={(e) =>
                                        handleMinusClick("infant", e)
                                      }
                                    >
                                      -
                                    </a>
                                    <input
                                      type="text"
                                      value={
                                        infant ? infant : formValues.infants
                                      }
                                      id="ddlInfant"
                                      className="txt_trav"
                                      readonly="readonly"
                                      onkeypress="return false;"
                                    />
                                    <a
                                      href="javascript:void(0)"
                                      className="plus"
                                      onClick={(e) =>
                                        handlePlusClick("infant", e)
                                      }
                                    >
                                      +
                                    </a>
                                  </div>
                                </div>
                                <div className="trav_item">
                                  <div className="trav_inner1">
                                    Infants (On Seat)
                                    <span>under 2 yrs</span>
                                  </div>
                                  <div className="trav_inner2">
                                    <a
                                      href="javascript:void(0)"
                                      className="minus"
                                      onClick={(e) =>
                                        handleMinusClick("infantWs", e)
                                      }
                                    >
                                      -
                                    </a>
                                    <input
                                      type="text"
                                      value={
                                        infantWs
                                          ? infantWs
                                          : formValues.infantsWs
                                      }
                                      id="ddlInfant"
                                      className="txt_trav"
                                      readonly="readonly"
                                      onkeypress="return false;"
                                    />
                                    <a
                                      href="javascript:void(0)"
                                      className="plus"
                                      onClick={(e) =>
                                        handlePlusClick("infantWs", e)
                                      }
                                    >
                                      +
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-5">
                                <h6 className="mt-2">Cabin</h6>
                                <div className="form-check">
                                  <input
                                    className="form-check-input clr-chng"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="1"
                                    checked={formValues.cabinType === "1"}
                                    onChange={(event) =>
                                      setFormValues((prevState) => ({
                                        ...prevState,
                                        cabinType: event.target.value,
                                      }))
                                    }
                                    id="flexRadioDefault1"
                                  />
                                  <label
                                    className="form-check-label wrse"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    Economy
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    checked={formValues.cabinType === "2"}
                                    onChange={(event) =>
                                      setFormValues((prevState) => ({
                                        ...prevState,
                                        cabinType: event.target.value,
                                      }))
                                    }
                                    id="flexRadioDefault1"
                                  />
                                  <label
                                    className="form-check-label wrse"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    Premium Economy
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="3"
                                    checked={formValues.cabinType === "3"}
                                    onChange={(event) =>
                                      setFormValues((prevState) => ({
                                        ...prevState,
                                        cabinType: event.target.value,
                                      }))
                                    }
                                    id="flexRadioDefault1"
                                  />
                                  <label
                                    className="form-check-label wrse"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    Business
                                  </label>
                                </div>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="flexRadioDefault"
                                    value="4"
                                    checked={formValues.cabinType === "4"}
                                    onChange={(event) =>
                                      setFormValues((prevState) => ({
                                        ...prevState,
                                        cabinType: event.target.value,
                                      }))
                                    }
                                    id="flexRadioDefault1"
                                  />
                                  <label
                                    className="form-check-label wrse"
                                    htmlFor="flexRadioDefault1"
                                  >
                                    First Class
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="trav_item d-flex justify-content-between align-items-center">
                            <p className="mb-0 me-2 wrse">
                              Before confirming your itinerary, you can refer to
                              our Terms & Conditions for convenient flight
                              bookings.{" "}
                            </p>
                            <span
                              className="trav_done"
                              onClick={hideTravellerBlock}
                            >
                              Apply
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 col-lg-2 text-center">
                    <button
                      className="btn btn-primary btn-clr-chng"
                      onClick={handleViewSec}
                    >
                      Search{" "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* <div className="col-lg-12 d-flex align-items-center justify-content-center dtdtd">
                    <img className="arrow-right srghy py-2" src={Right} alt="" />
                    
                  </div> */}
          {/* <div className="col-lg-12 d-flex justify-content-center align-items-center">
            {selectedOriginOption?.countryCode !== "GB" ? (
              <Link to="tel:+1-866-660-6330">
                {/* <h4 className="text-white zed wrfdsdf mt-3 mb-2 detges">Best Flight Deals In Just 1 Click</h4> */}
                {/* <img  src={Banner} alt="" /> 
                <picture>
                  <source media="(min-width: 768px)" srcSet={Banner} />
                  <img src={Banner2} alt="Best Flight Deals In Just 1 Click" />
                </picture>
              </Link>
            ) : (
              <Link to="tel:+0203-869-5270">
                {/* <h4 className="text-white zed wrfdsdf mt-3 mb-2 detges">Best Flight Deals In Just 1 Click</h4> */}
                {/* <img  src={Banner} alt="" /> 
                <picture>
                  <source media="(min-width: 768px)" srcSet={Banner} />
                  <img src={Banner2} alt="Best Flight Deals In Just 1 Click" />
                </picture>
              </Link>
            )}
          </div> */}
        </div>
      </div>
    </section>
  );
}
