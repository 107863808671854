/* eslint-disable no-unused-vars */
// Sidebar.js
import React from 'react';
import logo1 from '../../Resources/assest/img/logo1.png'
import san from '../../Resources/assest/img/listing/san.webp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHotel, faCar } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ metaFlights }) => {
    // Sort metaFlights alphabetically by sitename
    const sortedFlights = [...metaFlights].sort((a, b) => a.sitename.localeCompare(b.sitename));

    return (
        <div className="col-lg-3 hutd674">
            {sortedFlights.map((flight, index) => (
                <div className="d-flex align-items-center justify-content-around drag12 mb-2" key={index}>
                    <img className="me-3" src={`https://imgfolders.com/fareslist/sideLogo/${flight.sitename}.png`} alt="" />
                    <a href={flight.deeplink} target="_blank" rel="noopener noreferrer">
                        <div className="d-flex justify-content-around align-items-center btis">
                            <button className="button12 ms-0"><span className="text12">View</span><div className="wave12"></div></button>
                        </div>
                    </a>
                </div>
            ))}
        </div>
    );
};

export default Sidebar;
